<template>
    <div class="relative">
        <orderDetail></orderDetail>
    </div>
</template>
<script>
import orderDetail from '@/view/financeManager/comp/orderDetail';

export default {
    name: 'financeOrderView',
    components: {
        orderDetail,
    },
    data() {
        return {
            //
        };
    },
    mounted() {
        //
    },
    watch: {
        //
    },
    methods: {
        //
    },
};
</script>

<style lang="less" scoped></style>
